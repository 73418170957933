import PropTypes from 'prop-types';
import { Grid, IconButton } from '@mui/material';
import { useFieldArray } from 'react-hook-form';
import AddIcon from '@mui/icons-material/Add';
import ClearIcon from '@mui/icons-material/Clear';

import FormTextField from '../formTextField';
import FormAutocomplete from '../formAutocomplete';
import FormSwitch from '../formSwitch';
import useStyles from './styles';
import dataLists from '../../../utils/dataLists';
import useHandleFileUpload from '../hooks/useHandleFileUpload';
import Button from '../../button';
import FileUploadInput from '../fileUploadInput';

const {
  schoolOptions,
  subjectOptions,
  qualificationsOptions,
  examBoardOptions,
  degreeUniversityOptions,
  degreeLevelOptions,
  degreeSubjectOptions,
} = dataLists;

const FormTutorInfoFields = ({
  control,
  getValues,
  watch,
  setValue,
  Storage,
  // TODO: Temporary disable file uploads on profile page until supported by backend.
  showFileUploadFields,
}) => {
  const { classes } = useStyles();
  const { handleFileUpload, status } = useHandleFileUpload({
    fileDescriptor: 'dbs',
    setValue,
    field: 'dbsUploadFileName',
    Storage,
  });
  const { handleFileUpload: handleCvFileUpload, status: cvStatus } = useHandleFileUpload({
    fileDescriptor: 'cv',
    setValue,
    field: 'cvUploadFileName',
    Storage,
  });
  const { fields, remove, append } = useFieldArray({
    control,
    name: 'subjectList',
  });
  const watchSubjectList = watch('subjectList');

  const controlledSubjectFields = fields.map((field, index) => ({
    ...field,
    ...watchSubjectList[index],
  }));

  const {
    fields: fieldsExtraDegrees,
    remove: removeExtraDegrees,
    append: appendExtraDegrees,
  } = useFieldArray({
    control,
    name: 'extraDegrees',
  });

  const watchExtraDegrees = watch('extraDegrees');
  const controlledExtraDegreesFields = fieldsExtraDegrees.map((field, index) => ({
    ...field,
    ...watchExtraDegrees[index],
  }));

  const {
    fields: fieldsExtraSchools,
    remove: removeExtraSchools,
    append: appendExtraSchools,
  } = useFieldArray({
    control,
    name: 'extraSchools',
  });

  const watchExtraSchool = watch('extraSchools');
  const controlledExtraSchoolFields = fieldsExtraSchools.map((field, index) => ({
    ...field,
    ...watchExtraSchool[index],
  }));

  return (
    <>
      <Grid container columnSpacing={1} className={classes.spacer}>
        <Grid item xs={12}>
          <FormAutocomplete
            name="degree.university"
            control={control}
            options={degreeUniversityOptions}
            getOptionLabel={(option) => option}
            label="University attended?"
            defaultValue={() => [getValues('degree.university')]}
            inputProps={{
              label: 'Please enter',
              type: 'search',
              required: true,
            }}
            freeSolo
            handleHomeEndKeys
          />
        </Grid>
      </Grid>

      <Grid container spacing={1}>
        <Grid item xs={12} sm={5}>
          <FormAutocomplete
            name="degree.level"
            control={control}
            options={degreeLevelOptions}
            getOptionLabel={(option) => option}
            defaultValue={() => [getValues('degree.level')]}
            inputProps={{
              label: 'Degree',
              type: 'search',
              required: true,
            }}
            freeSolo
            handleHomeEndKeys
          />
        </Grid>
        <Grid item xs={12} sm={7}>
          <FormAutocomplete
            name="degree.subject"
            control={control}
            options={degreeSubjectOptions}
            getOptionLabel={(option) => option}
            defaultValue={() => [getValues('degree.subject')]}
            inputProps={{
              label: 'Degree subject',
              type: 'search',
              required: true,
            }}
            freeSolo
            handleHomeEndKeys
            required
          />
        </Grid>
      </Grid>

      {/* Dynamic degrees fields */}
      {controlledExtraDegreesFields.map((item, index) => (
        <div style={{
          paddingTop: 20,
        }}
        >
          <Grid
            container
            columnSpacing={1}
            className={classes.spacer}
          >
            <Grid
              item
              xs={11}
            >
              <FormAutocomplete
                name={`extraDegrees.${index}.university`}
                defaultValue={getValues(`extraDegrees.${index}.university`)}
                control={control}
                options={degreeUniversityOptions}
                getOptionLabel={(option) => option}
                inputProps={{
                  label: 'Please enter',
                  type: 'search',
                  required: true,
                }}
                freeSolo
                handleHomeEndKeys
              />
            </Grid>
            <Grid
              item
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: 20,
              }}
              xs={1}
            >
              <IconButton className={classes.deleteIcon} onClick={() => removeExtraDegrees(index)}>
                <ClearIcon />
              </IconButton>
            </Grid>
          </Grid>

          <Grid container spacing={1}>
            <Grid item xs={12} sm={5}>
              <FormAutocomplete
                name={`extraDegrees.${index}.level`}
                defaultValue={getValues(`extraDegrees.${index}.level`)}
                control={control}
                options={degreeLevelOptions}
                getOptionLabel={(option) => option}
                inputProps={{
                  label: 'Degree',
                  type: 'search',
                  required: true,
                }}
                freeSolo
                handleHomeEndKeys
              />
            </Grid>
            <Grid item xs={12} sm={7}>
              <FormAutocomplete
                name={`extraDegrees.${index}.subject`}
                defaultValue={getValues(`extraDegrees.${index}.subject`)}
                control={control}
                options={degreeSubjectOptions}
                getOptionLabel={(option) => option}
                inputProps={{
                  label: 'Degree subject',
                  type: 'search',
                  required: true,
                }}
                freeSolo
                handleHomeEndKeys
                required
              />
            </Grid>
          </Grid>
        </div>

      ))}
      <div className={classes.addButton}>
        <Button
          onClick={() => appendExtraDegrees({
            level: null,
            subject: null,
            university: null,
          })}
          variant="outlined"
          endIcon={<AddIcon />}
        >
          Add another Degree
        </Button>
      </div>

      <Grid container columnSpacing={1}>
        <Grid item xs={12} className={classes.sectionMargin}>
          <FormAutocomplete
            name="school.name"
            control={control}
            options={schoolOptions}
            getOptionLabel={(option) => option}
            label="Which school have you most recently taught at?"
            defaultValue={() => getValues('school.name')}
            inputProps={{
              label: 'Please Choose',
              type: 'search',
              required: true,
            }}
            freeSolo
            handleHomeEndKeys
          />
        </Grid>
        <Grid item xs={12}>
          <FormSwitch
            name="school.current"
            control={control}
            label="Currently Work Here?"
            defaultValue={getValues('school.current') ?? false}
          />
        </Grid>
      </Grid>

      {/* Dynamic schools fields */}
      {controlledExtraSchoolFields.map((item, index) => (
        <div style={{
        }}
        >
          <Grid
            container
            columnSpacing={1}
          >
            <Grid item xs={11} className={classes.sectionMargin}>
              <FormAutocomplete
                name={`extraSchools.${index}.name`}
                control={control}
                options={schoolOptions}
                getOptionLabel={(option) => option}
                defaultValue={() => getValues(`extraSchools.${index}.name`)}
                inputProps={{
                  label: 'Please Choose',
                  type: 'search',
                  required: true,
                }}
                freeSolo
                handleHomeEndKeys
              />
            </Grid>
            <Grid
              item
              xs={1}
            >
              <IconButton className={classes.deleteIcon} onClick={() => removeExtraSchools(index)}>
                <ClearIcon />
              </IconButton>
            </Grid>
          </Grid>
        </div>

      ))}
      <div className={classes.addButton}>
        <Button
          onClick={() => appendExtraSchools({
            name: null,
          })}
          variant="outlined"
          endIcon={<AddIcon />}
        >
          Add another School
        </Button>
      </div>

      {/* Dynamic subject fields */}
      {controlledSubjectFields.map((item, index) => (
        <Grid container spacing={1} key={item.id}>
          <Grid item xs={index === 0 ? 12 : 11} className={classes.sectionMargin}>
            <FormAutocomplete
              name={`subjectList.${index}.subject`}
              defaultValue={getValues(`subjectList.${index}.subject`)}
              control={control}
              options={subjectOptions}
              getOptionLabel={(option) => option.subjectName}
              label={index === 0 ? 'Which subjects can you tutor?' : ''}
              inputProps={{
                label: 'Subject',
                required: true,
              }}
            />
          </Grid>
          {/* Prevent the user from removing all subject fields */}
          {(index > 0) && (
          <Grid item xs={1}>
            <IconButton className={classes.deleteIcon} onClick={() => remove(index)}>
              <ClearIcon />
            </IconButton>
          </Grid>
          )}
          {/* TODO: Only allow selection of listed qualification level with a matching subject. */}
          <Grid item xs={12} sm={6}>
            <FormAutocomplete
              name={`subjectList.${index}.qualificationLevel`}
              defaultValue={getValues(`subjectList.${index}.qualificationLevel`)}
              control={control}
              options={qualificationsOptions}
              getOptionLabel={(option) => option.qualificationLevel}
              inputProps={{
                label: 'Qualification Level',
                required: true,
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormAutocomplete
              name={`subjectList.${index}.examBoard`}
              defaultValue={getValues(`subjectList.${index}.examBoard`)}
              control={control}
              options={examBoardOptions}
              getOptionLabel={(option) => option.examBoardName}
              inputProps={{
                label: 'Exam Board',
                required: true,
              }}
            />
          </Grid>
        </Grid>
      ))}
      <div className={classes.addButton}>
        <Button
          onClick={() => append({
            subject: null,
            qualificationLevel: null,
            examBoard: null,
          })}
          variant="outlined"
          endIcon={<AddIcon />}
        >
          Add another Subject
        </Button>
      </div>

      <Grid container columnSpacing={1}>
        <Grid item xs={12}>
          <FormTextField
            name="aboutYou"
            control={control}
            label="About you"
            inputProps={{
              multiline: true,
              rows: 7,
              margin: 'dense',
              placeholder: `1. What do you enjoy about teaching?
2. What is your teaching philosophy?
Please write up to 1000 characters
          `,
            }}
          />
        </Grid>
        {showFileUploadFields && (
        <>
          <Grid item xs={12}>
            <FileUploadInput
              name="dbsFile"
              label="Attach your D.B.S certificate"
              handleFileUpload={handleFileUpload}
              status={status}
            />
          </Grid>
          <Grid item xs={12}>
            <FileUploadInput
              name="cvFile"
              label="Attach your CV (optional)"
              handleFileUpload={handleCvFileUpload}
              status={cvStatus}
            />
          </Grid>
        </>
        )}
      </Grid>
    </>
  );
};

FormTutorInfoFields.defaultProps = {
  showFileUploadFields: true,
};

FormTutorInfoFields.propTypes = {
  control: PropTypes.shape({}).isRequired,
  getValues: PropTypes.func.isRequired,
  watch: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
  Storage: PropTypes.shape({}).isRequired,
  showFileUploadFields: PropTypes.bool,
};

export default FormTutorInfoFields;
