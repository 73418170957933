import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()({
  subjectFields: {
    marginTop: 24,
  },
  addButton: {
    paddingTop: 8,
  },
  deleteIcon: {
    marginTop: 32,
  },
  label: {
    paddingBottom: 8,
    color: '#162C5F',
    fontWeight: 500,
    fontSize: 16,
  },
});

export default useStyles;
