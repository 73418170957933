import schoolOptions from './schoolOptions.json';
import subjectOptions from './subjectOptions.json';
import qualificationsOptions from './qualificationsOptions.json';
import examBoardOptions from './examBoardOptions.json';
import academicYearOptions from './academicYearOptions.json';
import academicValuesOptions from './academicValuesOptions.json';
import academicTypeOptions from './academicTypeOptions.json';
import countryOptions from './countryOptions.json';
import countryCodeOptions from './countryCodeOptions.json';
import lessonDurationOptions from './lessonDurationOptions.json';
import degreeUniversityOptions from './degreeUniversityOptions.json';
import degreeLevelOptions from './degreeLevelOptions.json';
import degreeSubjectOptions from './degreeSubjectOptions.json';

const dataLists = {
  schoolOptions,
  subjectOptions,
  qualificationsOptions,
  examBoardOptions,
  academicYearOptions,
  countryOptions,
  countryCodeOptions,
  lessonDurationOptions,
  degreeUniversityOptions,
  degreeLevelOptions,
  degreeSubjectOptions,
  academicValuesOptions,
  academicTypeOptions,
};

export default dataLists;
