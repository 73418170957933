import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import {
  Autocomplete, TextField, Typography,
} from '@mui/material';
import useStyles from './styles';

const FormAutocomplete = ({
  control,
  name,
  label,
  options,
  rules,
  inputProps,
  defaultValue,
  multiple,
  readOnly,
  freeSolo,
  ...otherProps
}) => {
  const { classes } = useStyles();

  // Prevent warnings for default values
  // https://github.com/mui/material-ui/issues/18514#issuecomment-695817110
  const getOptions = (value) => {
    if (multiple) {
      return !value.length ? options : [...value, ...options];
    }
    return value === null ? options : [value, ...options];
  };

  return (
    <>
      {label && (
      <Typography variant="body1" className={classes.label}>
        {label}
      </Typography>
      )}
      <Controller
        name={name}
        control={control}
        rules={rules}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <Autocomplete
            options={getOptions(value)}
            filterSelectedOptions
            defaultValue={defaultValue}
            value={value}
            onChange={(_, data) => {
              if (freeSolo) {
                onChange(data?.name ?? data);
              } else {
                onChange(data);
              }
            }}
            onInputChange={(_, data) => {
              if (freeSolo) {
                onChange(data);
              }
            }}
            multiple={multiple}
            readOnly={readOnly}
            {...otherProps}
            renderInput={(params) => (
              <TextField
                {...params}
                error={Boolean(error)}
                {...inputProps}
              />
            )}
          />
        )}
      />
    </>
  );
};

FormAutocomplete.defaultProps = {
  label: '',
  rules: {},
  inputProps: {},
  defaultValue: null,
  multiple: false,
  readOnly: false,
  freeSolo: false,
};

FormAutocomplete.propTypes = {
  control: PropTypes.shape({}).isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  inputProps: PropTypes.shape({}),
  rules: PropTypes.shape({}),
  options: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  defaultValue: PropTypes.oneOfType([
    PropTypes.shape({}),
    PropTypes.array,
  ]),
  multiple: PropTypes.bool,
  readOnly: PropTypes.bool,
  freeSolo: PropTypes.bool,
};

export default FormAutocomplete;
