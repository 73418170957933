import dayjs from 'dayjs';

export function getStatus(status, endDate) {
  switch (status) {
    case 'ACCEPTED':
    case 'COMPLETED':
    case 'PENDING':
      if (endDate > Date.now()) {
        return 'Upcoming';
      }

      return 'Past';
    case 'CANCELLED_NO_CHARGE':
      return 'Cancelled without charge';
    case 'CANCELLED_WITH_CHARGE':
      return 'Cancelled with charge';
    default:
      return 'Unknown';
  }
}

export default [
  {
    field: 'id',
    headerName: 'ID',
    flex: 3,
    valueGetter: (params) => params?.row?.id,
  },
  {
    field: 'startDate',
    headerName: 'Start',
    flex: 1.5,
    valueFormatter: ({ value }) => dayjs(value).format('DD-MM-YYYY HH:mm'),
  },
  {
    hide: true,
    field: 'endDate',
    headerName: 'Finish',
    flex: 1,
    valueFormatter: ({ value }) => dayjs(value).format('DD-MM-YYYY HH:mm'),
  },
  {
    headerName: 'Duration',
    flex: 0.8,
    valueGetter: (params) => dayjs(params?.row?.endDate).diff(dayjs(params?.row?.startDate), 'hour', true).toString(),
  },
  {
    field: 'tutor',
    headerName: 'Tutor',
    flex: 1.5,
    valueGetter: (params) => {
      const { firstName, lastName } = params?.row?.tutors?.[0] ?? {};

      return `${firstName} ${lastName}`;
    },
  },
  {
    field: 'subject',
    headerName: 'Subject',
    flex: 1,
    valueGetter: (params) => params?.row?.subject?.subjectName,
  },
  {
    field: 'learner',
    headerName: 'Learner',
    flex: 1,
    valueGetter: (params) => {
      const { fullName } = params?.row?.learners?.[0] ?? {};

      return fullName;
    },
  },
  {
    field: 'parent',
    headerName: 'Parent',
    flex: 1,
    valueGetter: (params) => {
      const { firstName, lastName } = params?.row?.learners?.[0].parent ?? {};

      return `${firstName} ${lastName}`;
    },
  },
  {
    field: 'lessonType',
    headerName: 'Type',
    flex: 1,
    valueGetter: (params) => (params?.row?.freeTrial ? 'Free trial' : 'Normal'),
  },
  {
    field: 'tutorFee',
    headerName: 'Tutor fee',
    flex: 1,
    valueGetter: (params) => `£${(params?.row?.chargeToPath ?? 0) / 100}`,
  },
  {
    field: 'parentFee',
    headerName: 'Parent fee',
    flex: 1,
    valueGetter: (params) => `£${(params?.row?.chargeToCustomer ?? 0) / 100}`,
  },
  {
    field: 'status',
    headerName: 'Status',
    flex: 1,
    valueGetter: (params) => getStatus(params?.row?.status, params?.row?.endDate),
  },
];
