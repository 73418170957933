import { useEffect, useState } from 'react';
import config from '../config';

export const getUserType = (groups) => {
  const { UserType } = config;
  if (groups?.includes(UserType.ADMIN)) {
    return UserType.ADMIN;
  }
  if (groups?.includes(UserType.TUTOR)) {
    return UserType.TUTOR;
  }
  if (groups?.includes(UserType.PARENT)) {
    return UserType.PARENT;
  }
  if (groups?.includes(UserType.LEARNER)) {
    return UserType.LEARNER;
  }

  return null;
};

const useAuth = ({ Hub, Auth, bypassCache }) => {
  const [cognitoUser, setCognitoUser] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const updateUser = async () => {
      try {
        const newUser = await Auth.currentAuthenticatedUser({ bypassCache });
        setCognitoUser(newUser);
        setIsAuthenticated(true);
      } catch {
        setCognitoUser(null);
        setIsAuthenticated(false);
      }
      setLoading(false);
    };
    Hub.listen('auth', updateUser); // listen for login/signup events
    updateUser();
    return () => Hub.remove('auth', updateUser); // cleanup
  }, [bypassCache]);

  const handleSignIn = async (email, password) => {
    const res = await Auth.signIn(email, password);
    setCognitoUser(res);
    return res;
  };

  const handleCompleteNewPassword = async (newUser, password) => {
    const res = await Auth.completeNewPassword(newUser, password);
    setCognitoUser(res);
    return res;
  };

  const handleForgottenPassword = async (emailAddress) => {
    const res = await Auth.forgotPassword(emailAddress);
    setCognitoUser(res);
    return res;
  };

  const handleForgottenPasswordSubmit = async (emailAddress, code, newPassword) => {
    const res = await Auth.forgotPasswordSubmit(emailAddress, code, newPassword);
    setCognitoUser(res);
    return res;
  };

  const handleChangePassword = async ({ currentPassword, newPassword }) => {
    try {
      return await Auth.changePassword(cognitoUser, currentPassword, newPassword);
    } catch (error) {
      if (error.name === 'LimitExceededException' || error.name === 'TooManyRequestsException') {
        return 'LIMIT';
      }
      if (error.name === 'NotAuthorizedException') {
        return 'NOAUTH';
      }
      return 'ERROR';
    }
  };

  const handleChangeEmail = async ({ email }) => {
    try {
      return await Auth.updateUserAttributes(cognitoUser, {
        email,
      });
    } catch (error) {
      if (error.name === 'LimitExceededException' || error.name === 'TooManyRequestsException') {
        return 'LIMIT';
      }
      if (error.name === 'NotAuthorizedException') {
        return 'NOAUTH';
      }
      return 'ERROR';
    }
  };

  const handleSignOut = async () => {
    try {
      await Auth.signOut();
    } catch (error) {
      throw new Error(error);
    }
  };

  const groups = cognitoUser?.signInUserSession?.accessToken?.payload['cognito:groups'];
  const isUnverified = groups?.includes('unverified');

  const user = {
    id: cognitoUser?.attributes?.sub ?? '',
    email: cognitoUser?.attributes?.email ?? '',
    agreement: cognitoUser?.signInUserSession?.idToken?.payload?.agreement,
    docusignEnvelopeId: cognitoUser?.signInUserSession?.idToken?.payload?.docusignEnvelopeId,
    isAuthenticated,
    isAdmin: groups?.includes('admin') ?? false,
    isVerified: !isUnverified ?? false,
    userType: getUserType(groups),
    groups,
  };

  return {
    user,
    loading,
    handleSignOut,
    handleSignIn,
    handleCompleteNewPassword,
    handleForgottenPassword,
    handleForgottenPasswordSubmit,
    handleChangePassword,
    handleChangeEmail,
    cognitoUser,
  };
};

export default useAuth;
