import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()({
  spacer: {
    paddingBottom: 8,
  },
  sectionMargin: {
    marginTop: 24,
  },
  addButton: {
    paddingTop: 8,
  },
  deleteIcon: {
    marginTop: 32,
  },
});

export default useStyles;
